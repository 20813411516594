import forEach from 'lodash/forEach'
import 'intersection-observer'
import browserUpdate from 'browser-update'
import yall from 'yall-js'
import smoothscroll from 'smoothscroll-polyfill'

browserUpdate({ required: { i: 12, e: 16 }, test: false, noclose: true, no_permanent_hide: true})

smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', () => {

  yall({
    observeChanges: true,
    lazyClass: 'lazy-load-resource',
    idleLoadTimeout: 200,
    threshold: 400,
  })

  // Single image modal pop up
  let imagePopups = document.querySelectorAll(".launch-image-popup")
  const imageModal = document.querySelector(".image-pop-up.modal")
  let close

  // If Image Modal exists on page
  if(imageModal) {
    close = imageModal.querySelector(".close")

    forEach(imagePopups, popup => {
      popup.addEventListener("click", e => {

        let newImagePlacement = document.createElement("figure")
        newImagePlacement.classList.add("image","single")
        const imagePlacement = imageModal.querySelector(".image")

        if(e.currentTarget.tagName == "IMG") {
          var image = document.createElement("img")
          image.src = e.currentTarget.src
          imagePlacement.parentNode.replaceChild(newImagePlacement, imagePlacement)
          newImagePlacement.appendChild(image)
          imageModal.classList.add("show")
          imageModal.classList.remove("hide")
        }
      })
    })

    close.addEventListener("click", e => {
      e.preventDefault()
      imageModal.classList.remove("show")
      imageModal.classList.add("hide")
    })
  }

  // Hotel Descriptions Toggle for Small Devices
  let hotelDescs = document.querySelectorAll(".view-more-description")
  forEach(hotelDescs, desc => {
    desc.addEventListener("click", () => {
      let $parent = desc.parentNode

      if(desc.classList) {
        $parent.classList.toggle("open")
        desc.classList.toggle("less")
      } else {
        $parent.className = _toggleClassFallback($parent, "open")
        desc.className = _toggleClassFallback(desc, "less")
      }
    })
  })

  // Blog Page
  let blogSignUpFormButton = document.getElementById("toggle-blog-sign-up-form")
  if(blogSignUpFormButton) {
    let blogCloseBtn = document.getElementById("close-blog-sign-up-form")
    let blogForm = document.getElementById("new_vof_news_letter_recipient")

    blogSignUpFormButton.addEventListener("click", () => {
      blogForm.classList.toggle("open")
      blogSignUpFormButton.classList.toggle("hidden")
      blogCloseBtn.classList.toggle("hidden")
    })

    blogCloseBtn.addEventListener("click", () => {
      blogForm.classList.toggle("open")
      blogSignUpFormButton.classList.toggle("hidden")
      blogCloseBtn.classList.toggle("hidden")
    })
  }

})

const _toggleClassFallback = (el, className) => {
  var classes = el.className.split(' ')
  var existingIndex = classes.indexOf(className)

  if (existingIndex >= 0)
    classes.splice(existingIndex, 1)
  else
    classes.push(className)

  return classes.join(' ')
}

// Polyfills

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
if (!Array.prototype.flat) {
  Array.prototype.flat = function(depth) {
    var flattend = [];
    (function flat(array, depth) {
      for (let el of array) {
        if (Array.isArray(el) && depth > 0) {
          flat(el, depth - 1);
        } else {
          flattend.push(el);
        }
      }
    })(this, Math.floor(depth) || 1);
    return flattend;
  };
}

// Object.keys polyfill

if (!Object.keys) {
  Object.keys = function(obj) {
    var keys = [];

    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        keys.push(i);
      }
    }

    return keys;
  };
}

// Polyfill for IE11 padStart

// Polyfill for Repeat
if (!String.prototype.repeat) {
  String.prototype.repeat = function(count) {
    'use strict';
    if (this == null) {
      throw new TypeError('can\'t convert ' + this + ' to object');
    }
    var str = '' + this;
    count = +count;
    if (count != count) {
      count = 0;
    }
    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }
    if (count == Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }
    count = Math.floor(count);
    if (str.length == 0 || count == 0) {
      return '';
    }
    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }
    var maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
       str += str;
       count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  }
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength,padString) {
      targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
      padString = String((typeof padString !== 'undefined' ? padString : ' '));
      if (this.length > targetLength) {
          return String(this);
      }
      else {
          targetLength = targetLength-this.length;
          if (targetLength > padString.length) {
              padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
          }
          return padString.slice(0,targetLength) + String(this);
      }
  };
}

//Polyfil for Number.isInteger
Number.isInteger = Number.isInteger || function(value) {
  return typeof value === 'number' &&
    isFinite(value) &&
    Math.floor(value) === value;
}
